// @flow

import { SpearsOrdersToCSSNumbers } from 'constants/OdooConstants';
import { isDefined, isEmpty, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import type { OdooSalesOrder } from 'types/Odoo.types';
import type { TbAssetTelemetriesExtendedItem, TelemetryAttribute } from 'types/Thingsboard.types';
import { FARMER_DEVICE_ATTRIBUTE_ENUM } from 'constants/DeviceConstants';
import { updateTelemetryAttribute } from 'api/service/ThingsboardManagerTelemetryService';
import { Dispatch } from '@reduxjs/toolkit';
import { MANAGER_TENANT_ATTRIBUTE_ENUM } from 'constants/TenantConstants';
import { updateOdooTenantNote } from 'api/service/OdooApiService';

export const generatePassword = (): string => {
  let text = '';
  let availableLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < 12; i++) {
    text += availableLetters.charAt(Math.floor(Math.random() * availableLetters.length));
  }

  return text;
};

// Note: Used for migration of the order numbers from a file
export const handlePopulateOrderNumbersClick = (
  spears: Array<TbAssetTelemetriesExtendedItem>,
  odooSalesOrders: Array<OdooSalesOrder>,
  dispatch: Dispatch
) => {
  const notFoundInFile = [];
  const foundInFileNoOrderNumber = [];
  const notFoundInOdoo = [];

  // Note: Returns a Promise that resolves after "ms" Milliseconds
  const timer = (ms) => new Promise((res) => setTimeout(res, ms));

  async function execute() {
    for (const spear of spears) {
      const index = spears.indexOf(spear);
      let matchedSpearsOrderNumbersPair = SpearsOrdersToCSSNumbers.find(
        (spearSaleOrder) => spear.assetDto.name === `CSS-${spearSaleOrder.serialNumber}`
      );
      if (isNotEmpty(matchedSpearsOrderNumbersPair)) {
        if (isNotEmpty(matchedSpearsOrderNumbersPair.orderNumber)) {
          let matchedSalesOrder = odooSalesOrders.find((salesOrder) =>
            salesOrder.name.includes(matchedSpearsOrderNumbersPair.orderNumber?.toString())
          );

          //Note: check if the sales order with that name exists and the device can be updated with the order number
          if (isNotEmpty(matchedSalesOrder)) {
            updateDeviceOrderNumber(spear, matchedSalesOrder.name, index, dispatch);
          }

          if (isEmpty(matchedSalesOrder)) {
            notFoundInOdoo.push(spear.assetDto.name);
          }
        } else {
          foundInFileNoOrderNumber.push(spear.assetDto.name);
        }
      } else {
        notFoundInFile.push(spear.assetDto.name);
      }
      //Note: The created Promise can be awaited
      await timer(3000);
    }

    if (isNotEmptyList(notFoundInFile)) {
      console.log('NOT FOUND IN FILE: ');
      console.log(notFoundInFile.join(', '));
    }

    if (isNotEmptyList(foundInFileNoOrderNumber)) {
      console.log('FOUND IN FILE, BUT NO ORDER NUMBER: ');
      console.log(foundInFileNoOrderNumber.join(', '));
    }

    if (isNotEmptyList(notFoundInOdoo)) {
      console.log('NOT FOUND IN ODOO: ');
      console.log(notFoundInOdoo.join(', '));
    }

    console.log('FINISHED MIGRATING DATA');
  }

  // Note: Call the async execute function
  execute();
};

const updateDeviceOrderNumber = (device, orderNumber, index, dispatch) => {
  const body = JSON.stringify({
    [FARMER_DEVICE_ATTRIBUTE_ENUM.ORDER_NUMBER]: orderNumber
  });
  const newUpdateObject = {
    entityId: device.assetDto.id.id,
    entityType: device.assetDto.id.entityType,
    body: body
  };

  updateTelemetryAttribute(dispatch, newUpdateObject)
    .then(() => {
      console.log(`${index}: Device with name ${device.assetDto.name} was updated with order number ${orderNumber}`);
    })
    .catch(() =>
      console.log(
        `${index}: Device with name ${device.assetDto.name} wasn't updated successfully 
          with order number ${orderNumber}`
      )
    );
};

// Note: Used for migration of the on premise data
export const handleMigrateOnPremiseFlag = (tenants: Array<TbAssetTelemetriesExtendedItem>, dispatch) => {
  // Note: Returns a Promise that resolves after "ms" Milliseconds
  const timer = (ms) => new Promise((res) => setTimeout(res, ms));

  async function execute() {
    let index = 0;
    for (const tenant of tenants) {
      index = index + 1;
      let attributes = tenant.attributes;
      let onPremiseConfig = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ON_PREMISE_CONFIG
      )?.value;
      if (isNotEmpty(onPremiseConfig)) {
        console.log(`${index}. ${tenant.assetDto.name}`);
        const onPremiseBody = JSON.stringify({
          [MANAGER_TENANT_ATTRIBUTE_ENUM.IS_ON_PREMISE]: true
        });
        const onPremiseUpdateObject = {
          entityId: tenant?.assetDto?.id?.id,
          entityType: tenant?.assetDto?.id?.entityType,
          body: onPremiseBody
        };

        updateTelemetryAttribute(dispatch, onPremiseUpdateObject)
          .then(() => {
            console.log(`${index}: Tenant with name ${tenant.assetDto.name} was updated`);
          })
          .catch(() => console.log(`${index}: Device with name ${tenant.assetDto.name} wasn't updated successfully`));
      }

      //Note: The created Promise can be awaited
      await timer(3000);
    }
  }

  // Note: Call the async execute function
  execute();
};

// Note: Used for migration of the notes data from Tenant asset TB to Odoo tenant
export const handleMigrateNotesToOdoo = (tenants: Array<TbAssetTelemetriesExtendedItem>, odooTenants, dispatch) => {
  // Note: Returns a Promise that resolves after "ms" Milliseconds
  const timer = (ms) => new Promise((res) => setTimeout(res, ms));

  async function execute() {
    let index = 0;
    for (const tenant of tenants) {
      index = index + 1;
      let attributes = tenant.attributes;
      let tenantNote = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.TENANT_NOTE
      )?.value;
      let odooTenantId = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT
      )?.value;
      let odooTenant = odooTenants?.find((odooTenant) => odooTenant.id === odooTenantId);

      if (isNotEmpty(tenantNote)) {
        console.log(`${index}. ${tenant.assetDto.name}`);

        if (isDefined(odooTenant)) {
          let updatedOdooTenant = { ...odooTenant, description: tenantNote };

          updateOdooTenantNote(updatedOdooTenant, dispatch)
            .then(() => {
              console.log(`${index}: Tenant with name ${tenant.assetDto.name} was updated with note ${tenantNote}`);
            })
            .catch(() => console.log(`${index}: Device with name ${tenant.assetDto.name} wasn't updated successfully`));
        } else {
          console.log(`${index}: Tenant with name ${tenant.assetDto.name} is not connected to Odoo`);
        }
      }

      //Note: The created Promise can be awaited
      await timer(3000);
    }
  }

  // Note: Call the async execute function
  execute();
};
